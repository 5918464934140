<template>
  <div>
    <span class="mr-20">{{ $t('users.createNewUser') }}</span>
    <b-button
      variant="primary"
      class="btn-icon rounded-circle"
      :style="colorPrimaryBtn"
      @click="showModal"
    >
      <feather-icon icon="UserIcon"/>
    </b-button>
    <b-modal
      id="createRolModal"
      v-model="showUserModal"
      centered
      size="lg"
      :title="$t('users.createNewUser')"
      no-close-on-backdrop
      @close="resetForm"
    >
      <validation-observer ref="createUserRules">
        <b-form
          ref="formRegister"
          autocomplete="off"
        >
          <!-- User section -->
          <b-row>
            <b-col>
              <h5>
                <strong>{{ $t("users.modal.title.one") }}</strong>
              </h5>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="name">
                  {{ $t('users.userData.name') }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('users.userData.name')"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    type="text"
                    :placeholder="$t('users.userData.name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="name">
                  {{ $t("users.userData.firstName") }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('users.userData.firstName')"
                >
                  <b-form-input
                    id="name"
                    v-model="user.first_name"
                    type="text"
                    :placeholder="$t('users.userData.firstName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="name">
                  {{ $t("users.userData.lastName") }}
                </label>
                <b-form-input
                  id="name"
                  v-model="user.last_name"
                  type="text"
                  :placeholder="$t('users.userData.lastName')"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="name">
                  {{ $t("users.userData.email") }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|email"
                  :name="$t('users.userData.email')"
                >
                  <b-form-input
                    id="name"
                    v-model="user.email"
                    type="text"
                    :placeholder="$t('users.userData.email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <label>{{ $t("users.userData.gender") }} <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('users.userData.gender')"
              >
                <v-select
                  v-model="user.gender_id"
                  label="name"
                  :options="genders"
                  :reduce="gender => gender.id"
                  :placeholder="$t('users.userData.gender')"
                >
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <label>{{ $t("users.userData.nacionality") }} <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('users.userData.nacionality')"
              >
                <v-select
                  v-model="user.nationality_id"
                  label="name"
                  :options="nacionalities"
                  :reduce="nacionality => nacionality.id"
                  :placeholder="$t('users.userData.nacionality')"
                >
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="6"
            >
              <b-form-group>
                <label for="name">
                  {{ $t('users.userData.phone') }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('users.userData.phone')"
                >
                  <VuePhoneNumberInput
                    v-model="user.cell_phone"
                    valid-color="#98a1b3"
                    size="sm"
                    error-color="#cc296a"
                    color="#cc296a"
                    default-country-code="MX"
                    :translations="{
                      countrySelectorLabel: $t('generic.telephonePrefix'),
                      countrySelectorError: $t('generic.chooseCountry'),
                      phoneNumberLabel: $t('member.form.placeholder.enterCellPhone'),
                      example: $t('generic.example')
                    }"
                    @update="resultsCellPhone = $event"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Address section -->
          <b-row>
            <b-col>
              <h5 class="mt-1">
                <strong>{{ $t("accountSettings.address") }}</strong>
              </h5>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <label>{{ $t("accountSettings.address") }}</label>
                <b-form-input
                  id="address"
                  v-model="address.address"
                  type="text"
                  :placeholder="$t('member.form.placeholder.enterAddressFull')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="club-country-id">{{ $t('generic.country') }}</label>
                <v-select
                  id="club-country-id"
                  v-model="address.country_id"
                  label="name"
                  :options="countries"
                  :reduce="country => country.id"
                  :placeholder="$t('generic.selectCountry')"
                >
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="club-state-id">{{ $t('generic.state') }}</label>
                <v-select
                  id="club-state-id"
                  v-model="address.state_id"
                  label="name"
                  :options="states"
                  :disabled="address.country_id == null"
                  :reduce="state => state.id"
                  :placeholder="$t('generic.selectState')"
                >
                  <div slot="no-options">
                    {{ $t('generic.noRecordsFound') }}
                  </div>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <label for="name">
                  {{ $t("accountSettings.postalCode") }}
                </label>
                <b-form-input
                  id="name"
                  v-model="address.zip_code"
                  type="text"
                  :placeholder="$t('accountSettings.postalCode')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Assigned section -->
          <b-row>
            <b-col>
              <h5 class="mt-1">
                <strong>{{ $t("users.modal.title.two") }}</strong>
              </h5>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <!-- Is owner -->
            <b-col>
              <b-form-group
                v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
              >
                <label> {{ $t('generic.isAdminClub') }} </label><br>
                <label> {{ $t('generic.not') }} </label>
                <b-form-checkbox
                  v-model="isAdminClub"
                  name="isAdminClub"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  :disabled="Boolean(user.is_internal)"
                  class="custom-control-primary pl-1"
                >
                  <label
                    class="pb-1 pl-1"
                  > {{ $t('generic.yes') }} </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Is external -->
            <b-col>
              <b-form-group
                v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
              >
                <label> {{ $t('generic.isExternal') }} </label><br>
                <label> {{ $t('generic.not') }} </label>
                <b-form-checkbox
                  v-model="user.is_internal"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  class="custom-control-primary pl-1"
                >
                  <label
                    class="pb-1 pl-1"
                  > {{ $t('generic.yes') }} </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="Boolean(user.is_internal)">
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label>
                    {{ $t("roles.rol") }}
                    <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('roles.rol')"
                  >
                    <v-select
                      v-model="role_id"
                      label="name"
                      :options="internalRoles"
                      :reduce="role => role.id"
                      :placeholder="$t('roles.selectRole')"
                      :selectable="(item) => item.status"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <!-- Assigned options -->
          <template v-else>
            <b-row
              v-for="(item, index) in assigned"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>
                    {{ $t("corporate.club") }}
                    <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('corporate.club')"
                  >
                    <v-select
                      v-model="assigned[index].club_id"
                      label="name"
                      :options="clubs"
                      :reduce="club => club.id"
                      :placeholder="$t('corporate.selectSportClub')"
                      @input="setSelected(assigned[index].club_id, index)"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label>
                    {{ $t("roles.rol") }}
                    <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('roles.rol')"
                  >
                    <v-select
                      v-model="assigned[index].role_id"
                      label="name"
                      :options="roles[index]"
                      :reduce="role => role.id"
                      :placeholder="$t('roles.selectRole')"
                      :selectable="(item) => item.status"
                      :disabled="assigned[index].club_id == null"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <label for="name">
                    {{ $t("generic.jobPosition") }}
                    <span class="text-danger">*</span>
                  </label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('generic.jobPosition')"
                  >
                    <v-select
                      v-model="assigned[index].job_position_id"
                      label="name"
                      :options="jobs[index]"
                      :reduce="job => job.id"
                      :placeholder="$t('users.modal.selected.job')"
                      :selectable="(item) => item.status"
                      :disabled="assigned[index].club_id == null"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Buttons -->
              <div v-if="Boolean(isAdminClub)">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  style="margin-left: 17px;"
                  variant="secondary"
                  size="sm"
                  class="btn-remove-club"
                  pill
                  @click="removeClub"
                >
                  {{ $t('buttons.putOff') }}
                </b-button>
              </div>
            </b-row>
          </template>
          <!-- Buttons -->
          <div v-if="Boolean(isAdminClub)">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-add-club"
              pill
              @click="addClub"
            >
              <span>{{ $t('buttons.addClub') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="resetForm"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoadingCreate"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              variant="primary"
              style="margin-left: 17px;"
              pill
              :style="colorPrimaryBtn"
              @click="setUser"
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import Ripple from 'vue-ripple-directive'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import servicesCountry from '@/services/countryService'
import servicesUser from '@/services/userService'
import servicesRole from '@/services/roleService'
import servicesJob from '@/services/jobService'
import servicesClubs from '@/services/clubsService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    VSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    // clubs: {
    //   type: Array,
    //   default: null,
    // },
    nacionalities: {
      type: Array,
      default: null,
    },
    countries: {
      type: Array,
      default: null,
    },
    genders: {
      type: Array,
      default: null,
    },
    clubInfo: {
      type: Object,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showUserModal: false,
      isLoadingCreate: false,
      required,
      email,
      resultsCellPhone: null,
      multipleClub: false,
      isAdminClub: 0,
      user: {
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        gender_id: null,
        nationality_id: null,
        cell_phone: null,
        is_internal: 0,
      },
      address: {
        address: null,
        country_id: null,
        state_id: null,
        zip_code: null,
      },
      role_id: null,
      assigned: [
        {
          id: Date.now(),
          club_id: null,
          role_id: null,
          job_position_id: null,
        },
      ],
      // lists
      clubs: [],
      states: [],
      roles: [],
      internalRoles: [],
      jobs: [],
      filters: {
        idClub: null,
      },
    }
  },
  watch: {
    'user.is_internal': function (pInternal) {
      if (Boolean(pInternal)) {
        this.isAdminClub = 0
        this.getInternalRoles()
      }
    },
    'address.country_id': function (pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.address.state_id = null
            this.getStates(pCountry)
          }
        } else {
          this.address.state_id = null
          this.getStates(pCountry)
        }
      } else {
        this.address.state_id = null
      }
    },
    isAdminClub() {
      this.clearFilter()
    },
  },
  methods: {
    showModal() {
      this.fetchClubsByRole()
      this.showUserModal = true
    },
    async fetchClubsByRole() {
      try {
        const { data } = await servicesClubs.getClubsByRole()
        this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name, active: true }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getStates(countryHash) {
      try {
        const filter = { country_hash: countryHash }
        const { data } = await servicesCountry.getStates(filter)
        this.states = data.data.map(e => ({ id: e.hash, name: e.name }))
      } catch (error) {
        this.responseCatch(error)
      }
    },
    setUser() {
      this.$refs.createUserRules.validate().then(isValid => {
        if (isValid) {
          this.isLoadingCreate = true
          this.user.cell_phone = this.resultsCellPhone.formattedNumber
          let form = null
          if (Boolean(this.user.is_internal)) {
            form = {
              user: this.user,
              address: this.address,
              role_id: this.role_id,
            }
          } else {
            form = {
              user: this.user,
              address: this.address,
              assigned: this.assigned,
            }
          }
          servicesUser.setUser(form).then(({ data }) => {
            this.responseSuccessCreate(data.message)
            this.$emit('successfully')
            this.resetForm()
            this.isLoadingCreate = false
          }).catch(error => {
            this.isLoadingCreate = false
            this.responseCatch(error)
          })
        }
      })
    },
    async validateClub(idClub, index) {
      const validation = this.assigned.some((c, i) => {
        if (i != index) {
          return c.club_id === idClub
        }
      })
      if (validation) {
        const fields = {
          id: Date.now() + index,
          club_id: null,
          role_id: null,
          job_position_id: null,
        }
        this.$set(this.assigned, index, fields)
        this.$swal({
          title: this.$t('error-catch'),
          text: this.$t('the-club-has-been-previously-registered'),
          icon: 'error',
          confirmButtonText: this.$t('generic.accept'),
          confirmButtonColor: this.colorRGB,
        })
      }
    },
    async getInternalRoles() {
      try {
        const filter = { has_club: '0' }
        const { data } = await servicesRole.getRolesByRole({}, filter)
        const list = data.data.map(e => ({ id: e.hash, name: e.name, status: e.status }))
        this.internalRoles = list
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getRoles(clubId, index) {
      try {
        const filter = {
          club_hash: clubId,
          is_admin_club: this.isAdminClub.toString(),
          has_club: '1',
        }
        const { data } = await servicesRole.getRolesByRole({}, filter)
        const list = data.data.map(e => ({ id: e.hash, name: e.name, status: e.status }))
        this.$set(this.roles, index, list)
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async getJobPosition(clubId, index) {
      try {
        const filter = { club_hash: clubId }
        const { data } = await servicesJob.getJobs({}, filter)
        const list = data.data.map(e => ({ id: e.hash, name: e.name, status: e.status }))
        this.$set(this.jobs, index, list)
      } catch (error) {
        this.responseCatch(error)
      }
    },
    async setSelected(idClub, index) {
      this.assigned[index].role_id = null
      this.assigned[index].job_position_id = null

      if (idClub != null && index != null) {
        await this.validateClub(idClub, index)
        await this.getRoles(idClub, index)
        await this.getJobPosition(idClub, index)
      }
    },
    addClub() {
      this.assigned.push({
        id: Date.now(),
        club_id: null,
        role_id: null,
        job_position_id: null,
      })
    },
    clearFilter(index = 0) {
      if (index) {
        this.assigned[index].club_id = null
        this.assigned[index].role_id = null
        this.assigned[index].job_position_id = null
      } else {
        this.assigned = [{
          id: Date.now(),
          club_id: null,
          role_id: null,
          job_position_id: null,
        }]
      }
    },
    removeClub() {
      if (this.assigned.length == 1) {
        this.clearFilter()
      } else {
        this.assigned.pop()
      }
    },
    resetForm() {
      this.showUserModal = false
      this.filters.idClub = this.clubInfo.hash
      this.clearForm()
    },
    clearForm() {
      // user
      this.user.name = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.email = null
      this.user.gender_id = null
      this.user.nationality_id = null
      this.user.cell_phone = null
      this.user.is_imternal = 0
      this.role_id = null
      // address
      this.address.address = null
      this.address.country_id = null
      this.address.state_id = null
      this.address.zip_code = null
      // Assigned
      this.clearFilter()
      // filter roles
      this.isAdminClub = 0
    },
  },
}
</script>
<style scoped lang="scss">
.mr-20 {
  margin-right: 20px !important;
}
.btn-remove-club {
  top: 98%;
  position: absolute !important;
  left: 16% !important;
}
.btn-add-club {
  top: 98%;
  position: absolute !important;
  left: 3% !important;
}
</style>
